<style lang="less" scoped>
.goods-form {
  display: flex;
  height: 100%;
}
.goods-form-d {
  flex: 2;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-right: 2rem;
}
.goods-form-a {
  flex: 3;
  height: 100%;
  overflow: auto;
}
.goods-form-btn {
  margin-top: 1rem;
  display: flex;
  justify-content: center;
}
</style>

<template>
  <div class="goods-form">
    <div class="goods-form-d">
      <fm-title title-text="项目"></fm-title>
      <fm-form :inline="false" label-align="left" label-alone>
        <fm-form-item label="名称">
          <fm-input-new
            :required="true"
            style="width: 100%;"
            v-model="formData.name"
            placeholder="名称"
          ></fm-input-new>
        </fm-form-item>
        <fm-form-item label="资产类型 (点击右侧资产分类中选择)">
          <fm-input-new
            :required="false"
            style="width: 100%;"
            v-model="formData.assetsTypeLabel"
            :disabled="true"
            placeholder="点击左侧资产分类中选择"
          ></fm-input-new>
        </fm-form-item>
        <div class="goods-form-btn">
          <fm-btn @click="save">{{formData.id ? '保存' : '新增'}}</fm-btn>
          <fm-btn style="margin-left:3rem;" @click="$emit('close')">关闭</fm-btn>
        </div>
      </fm-form>
    </div>
    <div class="goods-form-a">
      <assets-type-tree :is-select="true" @change="assetsTypeChange"></assets-type-tree>
    </div>
  </div>
</template>

<script>
import AssetsTypeTree from '@/views/base/assets_type/assetsTypeTree'
import { goodsRequest } from '@/api'

export default {
  components: {
    AssetsTypeTree
  },
  props: {
    id: { type: Number, default: null }
  },
  data () {
    return {
      formData: {
        id: undefined,
        name: '',
        assetsTypeName: null,
        assetsTypeCode: null,
        assetsTypeId: null,
        assetsTypeLabel: ''
      }
    }
  },
  watch: {
    id: {
      handler () {
        this.loadAssetsData()
      }
    }
  },
  methods: {
    async save () {
      try {
        if (!this.formData.name) {
            throw new Error('请输入名称')
        }

        let parm = JSON.parse(JSON.stringify(this.formData))
        if (!parm.assetsTypeId) {
            parm.assetsTypeId = undefined
        }

        if (parm.id) {
          await goodsRequest.update(parm.id, parm)
          this.$notice.success({ title: '系统提示', desc: '项目已更新' })
        } else {
          parm = await goodsRequest.add(parm)
          this.$set(this.formData, 'id', parm.id)
          this.$notice.success({ title: '系统提示', desc: '项目已新增' })
        }

        await this.$store.dispatch('loadGoodsList', true)
        this.$emit('save', parm)
      } catch (e) {
        this.$notice.warning({ title: '系统提示', desc: e.message })
      }
    },
    assetsTypeChange (data) {
      if (data) {
        this.$set(this.formData, 'assetsTypeName', data.name)
        this.$set(this.formData, 'assetsTypeCode', data.code)
        this.$set(this.formData, 'assetsTypeId', data.id)
        this.$set(this.formData, 'assetsTypeLabel', data.code + ' ' + data.name)
      } else {
        this.$set(this.formData, 'assetsTypeName', '')
        this.$set(this.formData, 'assetsTypeCode', '')
        this.$set(this.formData, 'assetsTypeId', '')
        this.$set(this.formData, 'assetsTypeLabel', '')
      }
      this.$emit('type-change', data)
    },
    loadAssetsData () {
      let data = this.id ? this.$store.getters.goodsList.find(v => v.key === this.id) : null
      if (data) {
        this.$set(this.formData, 'id', data.data.id)
        this.$set(this.formData, 'name', data.data.name)
        this.$set(this.formData, 'assetsTypeName', data.data.assetsTypeName)
        this.$set(this.formData, 'assetsTypeCode', data.data.assetsTypeCode)
        this.$set(this.formData, 'assetsTypeId', data.data.assetsTypeId)
        this.$set(this.formData, 'assetsTypeLabel', data.data.assetsTypeId ? data.data.assetsTypeCode + ' ' + data.data.assetsTypeName : '')
      } else {
        this.$set(this.formData, 'id', undefined)
        this.$set(this.formData, 'name', '')
        this.$set(this.formData, 'assetsTypeName', '')
        this.$set(this.formData, 'assetsTypeCode', '')
        this.$set(this.formData, 'assetsTypeId', '')
        this.$set(this.formData, 'assetsTypeLabel', '')
      }
    }
  },
  async mounted () {
    await this.$store.dispatch('loadGoodsList')
    this.loadAssetsData()
  }
}
</script>
